export const BASE_ROUTER = {
    HOME: '/',
    TABLE: '/table',
    ABOUT: '/about',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    REQUEST_ACCOUNT: '/request-account',
    TABLE_BILL: '/table/bill',
    TABLE_Order: '/table/order',
    BILL_DETAIL: '/bills/detail',
    404: '/404',
    SETTINGS: '/settings',
    BILL: '/bills',
    ORDERS: '/orders',
    SETTINGS_PASSWORD: '/settings/password',
    SETTINGS_PRINTER: '/settings/printer',
};
